import React from "react"

const Label = ({ label, formField }) => {
    return (
        <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor={formField}
        >
            {label}
        </label>
    )
}

export default Label
